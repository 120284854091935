<template>
  <UCard
    :ui="{
      root: 'group hover:ring-yellow-400 overflow-hidden bg-neutral-900/60 backdrop-blur-lg relative hover:ring-2 duration-300 flex flex-col h-full',
      header: 'p-2 sm:p-4 border-none',
      body: 'p-2 sm:p-4 -mt-3 sm:-mt-5 h-full border-none',
      footer: 'p-0 sm:p-0 mt-auto border-t border-neutral-800',
    }"
  >
    <template #header>
      <div class="relative aspect-[4/5] overflow-hidden rounded-xl">
        <NuxtImg
          provider="storyblok"
          :modifiers="{ smart: true }"
          sizes="xs:50svw sm:50svw md:25svw lg:25svw xl:25svw xxl:20svw"
          :src="activity.content.feature_image.filename"
          :alt="activity.content.feature_image.alt"
          class="absolute inset-0 h-full w-full object-cover duration-300 group-hover:scale-105"
          loading="lazy"
          placeholder
        />
        <UTooltip
          v-if="activity.content.activity_type === 'signature'"
          text="Signature Activity"
          :content="{
            align: 'center',
            side: 'left',
            sideOffset: 8,
          }"
          :ui="{
            content: 'bg-neutral-950/80 backdrop-blur-lg',
            text: 'font-bold text-yellow-400',
          }"
          :delay-duration="0"
        >
          <UBadge
            class="absolute top-2 right-2 z-[2] rounded-full bg-neutral-950 p-1 backdrop-blur-lg hover:bg-neutral-950/80"
          >
            <UIcon name="i-lucide-badge-check" class="size-6 text-yellow-400" />
          </UBadge>
        </UTooltip>
        <UTooltip
          v-if="activity.content.activity_type === 'target'"
          text="Target Activity"
          :content="{
            align: 'center',
            side: 'left',
            sideOffset: 8,
          }"
          :ui="{
            content: 'bg-neutral-950/80 backdrop-blur-lg',
            text: 'font-bold text-yellow-400',
          }"
          :delay-duration="0"
        >
          <UBadge
            class="absolute top-2 right-2 z-[2] rounded-full bg-neutral-950 p-1 backdrop-blur-lg hover:bg-neutral-950/80"
          >
            <UIcon name="i-lucide-circle-stop" class="size-6 text-yellow-400" />
          </UBadge>
        </UTooltip>
        <UTooltip
          v-if="activity.content.activity_type === 'kids'"
          text="Kids Only"
          :content="{
            align: 'center',
            side: 'left',
            sideOffset: 8,
          }"
          :ui="{
            content: 'bg-neutral-950/80 backdrop-blur-lg',
            text: 'font-bold text-yellow-400',
          }"
          :delay-duration="0"
        >
          <UBadge
            class="absolute top-2 right-2 z-[2] rounded-full bg-neutral-950 p-1.5 backdrop-blur-lg hover:bg-neutral-950/80"
          >
            <UIcon name="i-lucide-puzzle" class="size-5 text-yellow-400" />
          </UBadge>
        </UTooltip>
        <UTooltip
          v-if="activity.content.activity_type === 'corporates'"
          text="Corporate Activity"
          :content="{
            align: 'center',
            side: 'left',
            sideOffset: 8,
          }"
          :ui="{
            content: 'bg-neutral-950/80 backdrop-blur-lg',
            text: 'font-bold text-yellow-400',
          }"
          :delay-duration="0"
        >
          <UBadge
            class="absolute top-2 right-2 z-[2] rounded-full bg-neutral-950 p-1.5 backdrop-blur-lg hover:bg-neutral-950/80"
          >
            <UIcon
              name="i-lucide-briefcase-business"
              class="size-5 text-yellow-400"
            />
          </UBadge>
        </UTooltip>
        <UTooltip
          v-if="packageCard"
          text="Package"
          :content="{
            align: 'center',
            side: 'left',
            sideOffset: 8,
          }"
          :ui="{
            content: 'bg-neutral-950/80 backdrop-blur-lg',
            text: 'font-bold text-yellow-400',
          }"
          :delay-duration="0"
        >
          <UBadge
            class="absolute top-2 right-2 z-[2] rounded-full bg-neutral-950 p-1.5 backdrop-blur-lg hover:bg-neutral-950/80"
          >
            <UIcon
              name="i-lucide-ticket-percent"
              class="size-5 text-yellow-400"
            />
          </UBadge>
        </UTooltip>
      </div>
    </template>
    <NuxtLink
      v-if="
        !route.path.includes('/groups') &&
        !route.path.includes('/events') &&
        props.type !== 'package'
      "
      :title="activity.name"
      :to="activityLink"
      class="absolute top-0 left-0 z-[1] h-full w-full"
    />
    <div
      v-else
      class="absolute top-0 left-0 z-[1] h-full w-full cursor-pointer"
      @click="toggleOpen"
    />
    <div class="relative">
      <div v-if="packageCard" class="mt-0.5 flex items-start space-x-1">
        <UTooltip
          :text="
            activity.content.locations
              .map((location?: any) => location.name)
              .join(', ')
          "
          :content="{
            align: 'center',
            side: 'bottom',
            sideOffset: 8,
          }"
        >
          <UIcon
            name="i-lucide-map-pin"
            class="size-4 shrink-0 text-yellow-400"
          />
          <div class="-mt-0.5 text-sm font-semibold text-yellow-400">
            {{
              activity.content.locations.length > 2
                ? `${activity.content.locations[0].name} and ${
                    activity.content.locations.length - 1
                  } more`
                : activity.content.locations
                    .map((location?: any) => location.name)
                    .join(', ')
            }}
          </div>
        </UTooltip>
      </div>
      <div
        class="mt-1 text-base font-extrabold text-white duration-300 group-hover:text-yellow-400 sm:text-lg"
      >
        {{ activity.name }}
      </div>
      <p
        v-if="
          !route.path.includes('/groups') &&
          !route.path.includes('/events') &&
          props.type !== 'package'
        "
        class="text-sm font-semibold text-neutral-400 sm:text-base"
      >
        <span v-if="!packageCard">
          From £{{ formatPrice(activity.content.products[0].price) }} per
          {{ activity.content.products[0].price_per_type }}
        </span>
        <span v-if="packageCard"
          >Usually £{{ formatPrice(activity.content.original_price) }}/{{
            activity.content.products[0].price_per_type
          }}</span
        >
      </p>

      <p
        v-else
        class="line-clamp-3 text-sm font-semibold text-neutral-400 sm:text-base"
      >
        {{ activity.content.lead }}
      </p>
    </div>
    <template #footer>
      <div
        v-if="!packageCard"
        class="grid grid-cols-1 divide-y divide-neutral-800 sm:divide-x sm:divide-y-0"
      >
        <div
          class="flex items-center justify-center p-2 font-semibold text-neutral-400"
        >
          <div v-for="i in 5" :key="i">
            <UIcon
              name="i-heroicons-star-solid"
              class="size-4 text-yellow-400"
            />
          </div>
          <span class="-mt-1 ml-1 text-sm"
            >{{ activity.content.review_count }}+</span
          >
        </div>
      </div>
      <div v-else class="relative isolate">
        <div
          class="absolute inset-0 h-full w-full animate-pulse opacity-20"
          style="animation-duration: 5s"
        >
          <LazyBrandTopography />
        </div>
        <div
          class="absolute inset-0 z-[1] h-full w-full animate-pulse bg-gradient-to-b from-transparent via-neutral-950/50 to-neutral-950"
          style="animation-duration: 5s"
        />
        <p
          class="relative z-[2] p-2 text-center text-sm font-extrabold text-yellow-400 uppercase"
        >
          Just £{{ formatPrice(activity.content.products[0].price) }} per
          {{ activity.content.products[0].price_per_type }}
        </p>
      </div>
    </template>
    <UModal
      v-model:open="open"
      :title="activity.name"
      :description="modalDescription"
      :close="{
        color: 'primary',
        variant: 'outline',
        class: 'rounded-full',
      }"
      :ui="{
        overlay: 'bg-neutral-950/60	backdrop-blur',
        content: 'bg-neutral-950/60 backdrop-blur-xl overflow-y-auto',
      }"
    >
      <template #body>
        <div
          v-if="activity.content.activity_type === 'corporates'"
          class="prose dark:prose-invert prose-neutral"
          v-html="renderRichText(activity.content.content)"
        />
        <div v-else class="prose dark:prose-invert prose-neutral font-medium">
          {{ activity.content.lead }}
        </div>
      </template>
      <template v-if="activity.content.activity_type !== 'corporates'" #footer>
        <UButton class="w-full justify-center text-center" :to="activityLink">
          Learn More
        </UButton>
      </template>
    </UModal>
  </UCard>
</template>

<script setup lang="ts">
const route = useRoute();
const { slugify } = useSlugify();
const { formatPrice } = useFormatPrice();

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  activity: {
    type: Object,
    default: () => ({}),
  },
  blok: {
    type: Object,
    default: () => ({}),
  },
  filtered: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  packageCard: {
    type: Boolean,
    default: false,
  },
});

const open = ref(false);

const toggleOpen = () => {
  open.value = !open.value;
};

const activityLink = computed(() => {
  if (props.packageCard !== false) {
    return `/packages/${props.activity.slug}`;
  } else if (props.type === 'location' && props.name) {
    return `/activities/${props.activity.slug}?location=${slugify(props.name)}`;
  } else if (props.type === 'filtered') {
    return `/activities/${props.activity.slug}?location=${slugify(props.filtered)}`;
  } else {
    return `/activities/${props.activity.slug}`;
  }
});

const modalDescription = computed(() => {
  switch (props.activity.content.activity_type) {
    case 'corporates':
      return props.activity.content.lead;
    default:
      return `Duration From: ${props.activity.content.products[0].duration}`;
  }
});
</script>
